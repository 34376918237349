body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Roboto", "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 13px;
}

:root {
  --moranblue: #3c5778;
  --moranred: #824c5c;
  --morangray: #a2b2c5;
  --moranlightgray: #f2f1fc;
  --morandarkgray: #303d44;
  --morangreen: #d5edd7;
}

.App {
  display: block;
}

.App-header {
  position: sticky;
  top: 0px;
  height: 21px;
  background-color: var(--moranred);
  text-align: center;
  display: block;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
  /* font-size: calc(10px + 2vmin); */
  font-size: 1.3em;
  font-weight: bold;
  color: white;
  padding: 8px;
  z-index: 6000;
}

.App-link {
  color: #61dafb;
}

.App-content {
  padding: 15px;
}

.page-header {
  position: sticky;
  top: 37px;
  z-index: 4500;
  background-color: var(--moranlightgray);
  padding: 7px 15px;
  min-height: 27px;
  border-bottom: var(--moranred) 1px solid;
  /* border: green 1px solid; */
}
.page-header::after {
  clear: both;
}

.page-heading {
  color: var(--moranblue);
  font-size: 1.2em;
  font-weight: bold;
  padding-top: 2px;
  float: left;
}

.page-buttons {
  float: right;
}

button {
  border-radius: 5px;
  margin: 0 3px;
  padding: 6px 12px;
  min-width: 5rem;
  background: #303d44;
  font-weight: bold;
  color: #fff;
  border: 1px solid black;
  /* font-size: 14px; */
  font-size: 12px;
}

button:hover {
  background: var(--morangreen);
  color: black;
  cursor: pointer;
}
button:focus,
button:active {
  background: #824c5c99;
  color: black;
  border-color: white;
}
button:disabled {
  background: #616166;
  cursor: default;
}

nav {
  padding: 0 15px;
  background-color: #e4e9e9;
  display: none;
}

nav > a {
  padding: 0.5em;
}

nav > a:first-child {
  padding-left: 0;
}

nav a {
  color: #404040;
  text-decoration: none;
  display: inline-block;
}

nav a:hover {
  text-decoration: underline;
}

a.active {
  color: var(--moranred);
  text-decoration: underline;
  cursor: default;
}

.field-label {
  font-weight: bold;
}

.heading {
  font-style: italic;
  /* font-size: smaller; */
}

.direction-summary {
  display: inline-block;
  /* font-size: 14px; */
  font-weight: normal;
  padding-left: 0px;
  padding-bottom: 10px;
  padding-right: 20px;
  text-align: left;
}

.timesheet-info {
  position: sticky;
  top: 79px;
  z-index: 4500;
  background-color: white;
  border-bottom: 1px solid var(--moranred);
  padding: 4px 15px 14px 15px;
}
.timesheet-info.locked {
  padding-bottom: 6px;
}
.timesheet-info-totals {
  background-color: white;
}

.timesheet-container > table {
  width: 100%;
  table-layout: fixed;
}
table.people-grid {
  width: 100%;
  table-layout: fixed;
}
/* table.people-grid td:nth-child(2) { */
table.people-grid td,
.timesheet-container th,
.timesheet-container td {
  overflow: hidden;
  text-overflow: ellipsis;
  /* white-space: nowrap; */
}
table.people-grid th {
  text-overflow: clip;
}
.time-management-grid {
  width: 100%;
}
.time-management-filter {
  position: relative;
  max-height: 200px;
}
.people-grid-container {
  position: relative;
}

.last-column-filter .filter-popdown-container {
  right: 35px;
}

.timesheet-tabs {
  display: block;
  margin-top: 1em;
}

.timesheet-tab {
  float: left;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border: solid 1px #000;
  border-bottom: 0;
  min-width: 10rem;
  text-align: center;
  padding: 0.4em;
  cursor: pointer;
}

.timesheet-tab.active {
  background-color: #3c5778;
  color: #fff;
  font-weight: bold;
}

.timesheet-container {
  clear: both;
  position: relative;
}

.timesheet-tablist {
  list-style: none;
  padding: 0;
  margin: 0;
}

.timesheet-tab-panel {
  clear: both;
}

table {
  border-collapse: collapse;
}

table td {
  text-align: center;
}

table td a {
  text-decoration: none;
}

.alternate-bkg {
  background-color: var(--moranlightgray);
}

tr:hover.normal-bkg,
tr:hover.alternate-bkg,
tr:hover input[type="text"]
/* ,tr:hover select  */ {
  background-color: var(--morangreen);
}

tr:focus-within,
tr:focus-within:hover
/* ,tr:focus-within select  */ {
  background-color: #824c5c99;
}
tr:focus-within:hover input,
tr:focus-within input {
  background-color: #824c5c33;
}

th,
td {
  padding: 2px 5px;
}

td {
  border: solid 1px var(--morangray);
}

th {
  border: solid 1px var(--moranblue);
  background-color: #a2b2c5;
  color: #ffffff;
  font-weight: bold;
}

/* table column widths based on a 12-column layout */
th.col-one-half {
  width: 4.166%;
}
th.col-one {
  width: 8.333%;
}
th.col-two {
  width: 16.666%;
}
th.col-three {
  width: 25%;
}
th.col-five {
  width: 41.667%;
}
th.col-six {
  width: 50%;
}

.start-column {
  border-left-width: 2px;
}

.end-column {
  border-right-width: 2px;
}
/* th.start-column {
  border-left-color: var(--moranblue);
}
th.end-column {
  border-right-color: var(--moranblue);
} */

.button-column {
  min-width: 88px;
}

.column-rate {
  width: 70px;
}

.timecard-no-border {
  border: none 0px;
  padding: 15px 0px;
  text-align: left;
}

.timecard-no-border button {
  margin-bottom: 1px;
}

.hours {
  text-align: right;
  width: 47px;
}

td.timesheet-summary {
  text-align: right;
  font-weight: bold;
  min-height: 1em;
  border: none;
}
td.timesheet-summary.hours {
  padding-top: 20px;
}
td.totals {
  border-top: 2px solid var(--morangray);
}

input[type="text"],
input[type="number"] {
  font-size: 1em;
  padding: 0px;
}

input[type="number"],
.number {
  text-align: right;
  -moz-appearance: textfield;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="checkbox"] {
  appearance: none;
  border: 1px solid #000;
  padding: 5px;
  position: relative;
  top: 2px;
}
input[type="checkbox"]:checked:after {
  content: "\2714";
  font-size: 12px;
  position: absolute;
  top: -3px;
  left: 1px;
}
.filter-popdown-container input[type="checkbox"] {
  top: 4px;
}

input.hours {
  width: 100%;
}

input.dollars {
  width: 100%;
}

input.search {
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  margin: 5px 5px;
  padding: 2px 2px 2px 30px;
  width: 16.2em;
}

input.comment {
  min-width: 400px;
  width: 60%;
}

input:disabled {
  background-color: #ffffff;
  border-width: 1px;
}

.people-header {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  padding: 5px;
}

.people-header-search {
  flex: 1 1;
  padding-bottom: 5px;
  text-align: left;
}
.people-header-types {
  flex: 1;
  text-align: right;
}
.people-header-buttons {
  text-align: right;
}

.header-left {
  display: inline-block;
  vertical-align: top;
  margin-right: 5px;
}

.header-right {
  text-align: right;
  float: right;
}

.modal-content .header-right {
  padding-right: 16px;
}

.filter-popdown-container {
  position: absolute;
  /* max-height: 55vh; */
  overflow: hidden;
  text-align: left;
  background-color: #ffffff;
  color: #000000;
  z-index: 4000;
  /* border: orange solid 3px; */
}
.filter-popdown {
  position: relative;
  box-sizing: border-box;
  max-height: calc(100vh - 140px);
  overflow-y: auto;
  border: #000000 solid 1px;
  /* border: #00ff00 solid 1px; */
  padding: 3px;
  font-weight: normal;
  display: none;
}
.time-management-filter .filter-popdown {
  max-height: calc(80vh - 185px);
}
.people-grid-container .filter-popdown {
  max-height: calc(80vh - 225px);
}
.people-header .filter-popdown {
  max-height: calc(80vh - 140px);
}

.filter-popdown.visible {
  display: block;
}

.clickable {
  cursor: pointer;
}

.clickable svg {
  width: 12px;
}

.dialog-fixed {
  margin: 0 0 35px 0;
  /* padding: 15px 0; */
  text-align: left;
}

/* .dialog-fixed .header-left.label { */
.header-left.label {
  font-weight: bold;
  color: #404040;
}
.header-left.label + div,
.types-to-add {
  min-height: 1.3em;
}

/* .dialog-fixed .header-left.select .clickable { */
.header-left.select .clickable {
  border: 1px solid #000;
  padding: 3px 5px;
  margin: 0 3px 0 0;
}

.scrollable {
  width: 100%;
  overflow-y: scroll;
  /* max-height: 60vh; */
  height: calc(100% - 95px);
  /* border-bottom: black solid 1px; */
  /* border-left: 0; */
}

.close-button {
  position: absolute;
  top: 6px;
  right: 15px;
  /* border: solid 1px white; */
  background-color: var(--moranred);
  padding: 2px 5px;
}

.close-button svg {
  /* color: #824c5c; */
  color: white;
  top: 0px;
  position: relative;
  font-size: 1.5em;
}

svg.active {
  color: var(--moranred);
}

.modal-container {
  position: fixed;
  height: 80vh;
  width: 80vw;
  top: 0px;
  left: 0px;
  text-align: center;
  margin: 10vh 10vw;
  z-index: 5200;
  /* border: red solid 1px; */
}
.modal {
  position: relative;
  display: inline-block;
  height: 100%;
  width: 100%;
  z-index: 5500;
  background-color: #ffffff;
  overflow-x: hidden;
  overflow-y: hidden;
  /* border: #cccccc solid 1px; */
  /* border: blue solid 1px; */
  box-shadow: 2px 5px 15px 0px #000000;
}
.modal.collapse {
  height: auto;
  width: auto;
  min-width: 800px;
}
.modal.collapse-x {
  width: auto;
}

.modal-backdrop {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 5000;
  background: rgba(0, 0, 0, 0.4);
}

/* .modal-backdrop.clear {
  background: rgba(0, 0, 0, 0);
} */

.modal-backdrop.timeout {
  z-index: 5100;
}

.modal.timeout {
  z-index: 5600;
  color: #ffffff;
  background: #824c5cee;
  width: 30%;
  height: auto;
  min-height: initial;
  padding: 1%;
  font-size: 0.8em;
}

.modal-header {
  background-color: var(--moranred);
  text-align: left;
  color: white;
  padding: 10px;
  font-weight: bold;
}

.modal-content {
  box-sizing: border-box;
  padding: 15px;
  /* height: 100%; */
  height: calc(100% - 37px);
}

.modal-content li {
  text-align: left;
}

.comment-icon {
  display: inline-block;
  vertical-align: top;
  padding: 5px;
}

.comment-details {
  display: inline-block;
  padding: 3px;
}

ol {
  margin: 0px;
}

.timecard-buttons {
  float: right;
  display: block;
  padding-bottom: 14px;
}

.approval-buttons button:first-child {
  margin-left: 0;
}
select {
  width: 100%;
  /* margin: 3px;
  padding: 5px; */
  margin: 0px;
  padding: 0px;
  font-size: 1em;
}
/* select.yes-no {
  width: 4em;
} */
select:hover {
  cursor: pointer;
}
option {
  padding: 0px;
  min-height: 1em;
  margin: 0px 0px;
  /* height: 0.5em; */
  /* font-size: 1em; */
}

.top-choice {
  font-weight: bold;
  /* font-style: italic;
  background-color: var(--moranlightgray);
  border-bottom: black 1px solid;
  box-shadow: 2px 4px 4px gray; */
}
.select-all {
  border-bottom: var(--moranlightgray) 1px solid;
  padding-bottom: 3px;
}

select.number {
  direction: rtl;
}
select.number option {
  /* text-align: right; */
  direction: ltr;
  /* direction: rtl; */
}

.pay-container,
.pay-container table {
  /* width: 775px; */
  margin: auto;
}

.pay-container table td {
  /* border: none; */
  /* padding: 1px 22px; */
  text-align: left;
}

.pay-container .timecard-buttons td {
  text-align: center;
}

.pay-period-dropdowns select {
  width: auto;
}

.error-container {
  display: inline-block;
  /* float: left; */
  width: 100%;
  text-align: center;
}
.error-container h3 {
  color: var(--moranred);
  margin: 0 0 5px 0;
  /* font-size: 1.5em; */
}

/* User Management dialog */
.user-container {
  display: flex;
  flex-direction: row;
  /* height: calc(80vh - 47px); */
  top: 0;
  text-align: left;
}
.user-list-container {
  display: flex;
  flex-direction: column;
  /* border: 1px solid var(--moranred); */
  padding: 0 20px;
  top: 0;
  bottom: 0;
  height: 80vh;
}
.user-selection-container {
  display: flex;
  flex-direction: column;
  /* border: 1px solid var(--moranblue); */
  padding: 0 20px 0 0;
  width: 360px;
  height: 80vh;
}
.user-list-results {
  flex-grow: 1;
  /* height: 100%; */
}
.user-list {
  border: 1px solid black;
  /* height: calc(80vh - 203px); */
  max-height: calc(80vh - 180px);
  text-align: left;
  margin: 5px 5px;
  padding: 3px;
  overflow-y: auto;
}
/* .user-list-name {
  margin-left: 3px;
} */
.user-list-name:hover {
  background-color: var(--morangreen);
  cursor: pointer;
}
.user-list-name.active {
  background-color: #824c5c99;
  /* background-color: var(--moranred); */
  /* color: var(--moranlightgray); */
}
.user-list-no-results {
  font-style: italic;
}
.user-list-message {
  max-width: 220px;
  min-height: 3em;
  margin-left: 0.3em;
  font-style: italic;
}
.user-edit-container {
  display: flex;
  flex-direction: column;
  text-align: left;
  /* border: 1px solid var(--moranred); */
}
.user-edit-fields {
  display: grid;
  grid-template-columns: auto auto;
  padding: 2px 5px;
  gap: 0.2em;
}
.user-edit-container input:disabled {
  border: none;
  margin: 2px 2px;
}
.user-edit-container input[type="text"] {
  width: calc(100% - 4px);
}
.user-edit-fields div {
  min-height: 19px;
}
.grid-button-row {
  grid-column: 1 / 3;
  /* border: 1px solid var(--moranred); */
}
.user-field-label {
  font-weight: bold;
}
/* .user-edit-divisions {
  margin: 0 20px 0 0;
} */
.user-edit-divisions h3 {
  margin-top: 0.5em;
  margin-bottom: 0em;
}
.user-division-list {
  border: 1px solid black;
  max-height: calc(80vh - 290px);
  /* text-align: left; */
  /* margin: 5px 5px; */
  /* padding: 3px; */
  overflow-y: auto;
}
.timesheet-locked {
  margin-left: 0.2em;
  color: black;
}
